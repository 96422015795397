import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Theme'
  },
  {
    name: 'User',
    url: '/user',
    icon: 'fa fa-user'
  },
  {
    name: 'Recruiter',
    url: '/admin/recruiter',
    icon: 'fa fa-user'
  },
  {
    name: 'Recruiter Request',
    url: '/admin/recruiter-request',
    icon: 'fa fa-user'
  },
  {
    name: 'Job Title',
    url: '/admin/job',
    icon: 'fa fa-tasks'
  },
  {
    name: 'Qualifications',
    url: '/admin/qualification',
    icon: 'fa fa-graduation-cap'
  },
  {
    name: 'Industries',
    url: '/admin/industries',
    icon: 'fa fa-industry'
  },
  {
    name: 'Jobs',
    url: '/admin/jobs',
    icon: 'fa fa-tasks'
  },
  {
    name: 'Enquiries',
    url: '/admin/enquiries',
    icon: 'fa fa-tasks'
  },
  {
    name: 'CMS Pages',
    url: '/admin/pages',
    icon: 'fa fa-file'
  },
  {
    name: 'Plans',
    url: '/admin/plans',
    icon: 'fa fa-tasks'
  },
  {
    name: 'Logout',
    url: '/logout',
    icon: 'fa fa-lock'
  },
  
  // {
  //   name: 'Register',
  //   url: '/register',
  //   icon: 'icon-star'
  // },
];
