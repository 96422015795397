import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { LogoutComponent } from './logout/logout.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  /* {
    path: 'user',
    component: CreateComponent,
    data: {
      title: 'User List'
    }
  }, */
  {
    path: '',
    component: DefaultLayoutComponent,
    // data: {
    //   title: 'Home'
    // },
    children: [
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'admin/recruiter',
        loadChildren: () => import('./views/recuriter/recuriter.module').then(m => m.RecuriterModule)
      },
      {
        path: 'admin/recruiter-request',
        loadChildren: () => import('./views/recuriter-verify-request/recuriter-verify-request.module').then(m => m.RecuriterVerifyRequestModule)
      },
      {
        path: 'admin/job',
        loadChildren: () => import('./views/job/job.module').then(m => m.JobModule)
      },
      {
        path: 'admin/qualification',
        loadChildren: () => import('./views/qualification/qualification.module').then(m => m.QualificationModule)
      },
      {
        path: 'admin/industries',
        loadChildren: () => import('./views/industry/industry.module').then(m => m.IndustryModule)
      },
      {
        path: 'admin/jobs',
        loadChildren: () => import('./views/company-jobs/company-jobs.module').then(m => m.CompanyJobsModule)
      },
      {
        path: 'admin/plans',
        loadChildren: () => import('./views/plan/plan.module').then(m => m.PlanModule)
      },
      {
        path: 'admin/enquiries',
        loadChildren: () => import('./views/enquiries/enquiries.module').then(m => m.EnquiryModule)
      },
      {
        path: 'admin/pages',
        loadChildren: () => import('./views/cmspages/cmspages.module').then(m => m.CmsPagesModule)
      },
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
