import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { first } from 'rxjs/operators';
import {AuthenticationService } from '../../_services';
import {AlertService } from '../notifications/alert.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit{
  loginForm: FormGroup;
   loading = false;
   submitted = false;
   returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        //redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
      //console.log(1);
      this.loginForm = this.formBuilder.group({
          email: ['', Validators.required],
          password: ['', Validators.required]
      });

      //get return url from route parameters or default to '/' 
      this.returnUrl = 'dashboard';
    }

    //convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {

        
        this.submitted = true;

       // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                   if(data.code==200){
                    //  localStorage.setItem('currentUser', JSON.stringify(data.userDetails));
                    if(data.userDetails.type == 1){
                        //console.log('dasasasdf',data);
                        this.returnUrl = "dashboard";
                    }
                       
                     this.router.navigate([this.returnUrl]);
                   }else{
                    this.alertService.error('Please enter correct email and password');
                    this.alertService.getAlert();
                    this.loading = false;
                    this.router.navigate(['/login']);
                   }
                },
                error => {
                    this.alertService.error(error);
                    this.alertService.getAlert();
                    this.loading = false;
                });
    }
 }
