import {Component, OnInit} from '@angular/core';
import { navItems } from '../../_nav';
import { AuthenticationService } from '../../_services/authentication.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit{

  constructor(private AuthenticationService : AuthenticationService) { 
        
  }
  public sidebarMinimized = false;
  public navItems = navItems;
  public fade = true;
  public currentUser:any;
  public new_alert_message = '';

  ngOnInit() {
    this.AuthenticationService.checklogin();

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }


}
